@import "../../../common";

.pmp-home-container-h5 {
  .first {
    width: 100%;
    height: mp2r(359);
    background: linear-gradient(to right, #FDEBE7, #FFF7F4);
    padding: mp2r(38) mp2r(9) mp2r(2) mp2r(37);

    .title {
      font-weight: bold;
      font-size: mp2r(28);
      color: #333333;
      line-height: mp2r(34);
      margin-bottom: mp2r(9);
    }
    .sub-title {
      width: mp2r(222);
      font-weight: 500;
      font-size: mp2r(19);
      color: #333333;
      line-height: mp2r(21);
      margin-bottom: mp2r(4);
    }
    .imgs {
      display: flex;
      justify-content: space-between;
      .icon {
        @include bg;
      }
      .download-btn {
        width: mp2r(120);
        height: mp2r(34);
        background-color: #F43900;
        color: #fff;
        line-height: mp2r(34);
        text-align: center;
        border-radius: mp2r(60);
        font-size: mp2r(16);
        font-weight: bolder;
        cursor: pointer;
        margin-top: mp2r(40);
      }
      .google-play {
        margin-top: mp2r(52);
        margin-right: mp2r(15);
        width: mp2r(121);
        height: mp2r(39);
        //background-image: url("../../../assets/mobile/google_play.png");
      }
      .banner-img {
        width: mp2r(192);
        height: mp2r(210);
        background-image: url("../../../assets/mobile/banner.png");
      }

    }



  }
  .second {
    padding: mp2r(42) mp2r(20) 0 mp2r(20);
    text-align: center;
    .title {
      font-weight: bold;
      font-size: mp2r(26);
      color: #000000;
      line-height: mp2r(32);
    }
    .sub-title {
      width: mp2r(335);
      font-size: mp2r(16);
      color: #000000;
      line-height: mp2r(18);
      margin: mp2r(14) 0 mp2r(28) 0;
    }
    .card {
      @include bg;
      width: mp2r(335);
      height: mp2r(366);
      background-image: url("../../../assets/mobile/bg.png");
      margin-bottom: mp2r(18);
      padding: mp2r(16) mp2r(12) 0 mp2r(12);

      .card-header {
        display: flex;
        align-items: center;
      }

      .card-title {
        width: mp2r(200);
        font-weight: bold;
        font-size: mp2r(18);
        color: #000000;
        line-height: mp2r(23);
        text-align: left;
        margin-left: mp2r(10);
      }

      .icon {
        @include bg;
        width: mp2r(100);
        height: mp2r(100);
      }
      .clock {
        background-image: url("../../../assets/mobile/clock.png");
      }
      .lock {
        background-image: url("../../../assets/mobile/lock.png");
      }
      .group {
        background-image: url("../../../assets/mobile/group.png");
      }
      .hand {
        background-image: url("../../../assets/mobile/hand.png");
      }

      .card-content {
        width: mp2r(311);
        font-size: mp2r(18);
        color: #000000;
        line-height: mp2r(22);
        text-align: left;
        margin-top: mp2r(6);
      }

    }

  }
  .third {
    padding: mp2r(43) mp2r(20) mp2r(174) mp2r(20);
    background-color: #FFEAE4;
    .title {
      width: mp2r(304);
      font-weight: bold;
      font-size: mp2r(26);
      color: #000000;
      line-height: mp2r(32);
      text-align: center;
      margin: 0 auto mp2r(43);
    }

    .icons {
      display: flex;
      align-items: center;

      .icon-title {
        margin-left: mp2r(10);
        width: mp2r(225);
        font-size: mp2r(20);
        color: #000000;
        line-height: mp2r(24);
      }
    }
    .icons-2, .icons-6 {
      flex-direction: row-reverse;
      text-align: right;
      margin-top: mp2r(-10);
      .icon-title {
        margin-left: 0;
        margin-right: mp2r(10);
      }
    }
    .icon {
      @include bg;
      width: mp2r(100);
      height: mp2r(102);
    }
    .icon-arrow {
      @include bg;
      width: mp2r(28);
      height: mp2r(58);
    }
    .cloud {
      background-image: url("../../../assets/mobile/cloud.png");
    }
    .verify {
      background-image: url("../../../assets/mobile/verify.png");
    }
    .note {
      background-image: url("../../../assets/mobile/note.png");
    }
    .cash {
      background-image: url("../../../assets/mobile/cash.png");
    }
    .arrow1 {
      margin-left: mp2r(36);
      margin-top: mp2r(8);
      background-image: url("../../../assets/mobile/arrow1.png");
    }
    .arrow2 {
      margin-left: mp2r(271);
      margin-top: mp2r(8);
      background-image: url("../../../assets/mobile/arrow2.png");
    }
    .arrow3 {
      margin-left: mp2r(36);
      margin-top: mp2r(8);
      background-image: url("../../../assets/mobile/arrow3.png");
    }





  }
  .cloudy {
    @include bg;
    width: 100%;
    height: mp2r(100);
    margin-top: mp2r(-100);
    background-image: url("../../../assets/mobile/cloudy.png");
  }

}
