@mixin bg {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
}

@function px2rem($px, $fontSize: 34) {
  @return calc(#{$px}rem / $fontSize)
}

@function mp2r($px, $fontSize: 14) {
  @return calc(#{$px}rem / $fontSize)
}
