@import "../../../common";

.politica-container-pc {
  .title-wrapper {
    height: px2rem(565);
    background-color: #FFECE7;
    text-align: center;
    padding-top: px2rem(123);
    position: relative;

    .title {
      font-weight: bold;
      font-size: px2rem(50);
      color: #F43900;
      line-height: px2rem(61);
    }
    .cloudy {
      @include bg;
      background-image: url("../../../assets/pc/cloudy.png");
      height: px2rem(329);
      position: absolute;
      bottom: px2rem(-50);
      left: 0;
      right: 0;
    }
  }

  .content {
    width: px2rem(1634);
    margin: px2rem(-100) auto px2rem(70) auto;
    z-index: 1;
    position: relative;
    font-size: px2rem(32);
    color: #999999;
    .title {
      font-size: px2rem(36);
      font-weight: bold;
    }
  }

}