@import "../../../common";

.politica-container-h5 {
  .title-wrapper {
    height: mp2r(198);
    background-color: #FFECE7;
    text-align: center;
    padding-top: mp2r(49);
    position: relative;

    .title {
      font-weight: bold;
      font-size: mp2r(18);
      color: #F43900;
      line-height: mp2r(23);
    }
    .cloudy {
      @include bg;
      background-image: url("../../../assets/mobile/cloudy.png");
      height: mp2r(100);
      position: absolute;
      bottom: mp2r(-30);
      left: 0;
      right: 0;
    }
  }
  .content {
    width: mp2r(335);
    margin: 0 auto mp2r(41);
    z-index: 1;
    position: relative;
    font-size: mp2r(16);
    color: #999999;
    .title {
      font-size: mp2r(18);
      font-weight: bold;
    }
  }
}