@import "../../../common";

.pmp-layout-h5 {
  font-size: mp2r(14);
  .header {
    .logo-wrapper {
      display: flex;
      align-items: center;
      margin-top: mp2r(10);
      margin-left: mp2r(20);
      margin-bottom: mp2r(12);
      .logo {
        background-image: url("../../../assets/mobile/logo.png");
        @include bg;
        width: mp2r(38);
        height: mp2r(38);
      }
      .text {
        white-space: nowrap;
        font-weight: 500;
        font-size: mp2r(14);
        color: #000000;
        margin-left: mp2r(6);
      }
    }

    .h5-menu-container {
      padding: mp2r(14) 0;
      .adm-capsule-tabs-tab {
        font-size: mp2r(14);
      }

    }

  }
  .footer {
    text-align: center;
    .title {
      font-weight: bold;
      font-size: mp2r(26);
      color: #000000;
      line-height: mp2r(32);
    }
    .desc {
      width: mp2r(327);
      font-size: mp2r(18);
      color: #9D9D9D;
      line-height: mp2r(22);
      margin: mp2r(18) auto mp2r(28) auto;
    }

    .list {
      display: flex;
      align-items: center;
      font-size: mp2r(16);
      text-align: left;
      margin-bottom: mp2r(40);
      margin-left: mp2r(37);

      &:nth-child(5) {
        margin-bottom: mp2r(28);
      }

      .icon {
        @include bg;
        width: mp2r(26);
        height: mp2r(26);
        margin-right: mp2r(8);
        flex: none;
      }
      .wa {
        background-image: url("../../../assets/mobile/wa.png");
      }
      .time {
        background-image: url("../../../assets/mobile/time.png");
      }
      .email {
        background-image: url("../../../assets/mobile/email.png");
        height: mp2r(19);
      }
      .address {
        background-image: url("../../../assets/mobile/address.png");
      }
    }

    .copy-right {
      font-size: mp2r(16);
      color: #000000;
      line-height: mp2r(18);
      margin-bottom: mp2r(28);
    }


  }

}
