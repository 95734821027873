@import "../../../common";

.pmp-layout-pc {
  .header {
    width: px2rem(1680);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: px2rem(132);
    .logo-wrapper {
      display: flex;
      align-items: center;
      .logo {
        background-image: url("../../../assets/pc/logo.png");
        @include bg;
        width: px2rem(80);
        height: px2rem(80);
      }
      .text {
        white-space: nowrap;
        font-weight: 500;
        font-size: px2rem(24);
        color: #000000;
        margin-left: px2rem(18);
      }
    }

  }
  .footer {
    height: px2rem(344);
    padding: px2rem(79) 0 px2rem(44) px2rem(417);
    .inner {
      display: flex;
    }

    .left {
      .title {
        font-weight: bold;
        font-size: px2rem(42);
        color: #000000;
        line-height: px2rem(51);
      }
      .desc {
        width: px2rem(419);
        font-size: px2rem(24);
        color: #9D9D9D;
        line-height: px2rem(29);
        //margin-top: px2rem(50);
      }
    }
    .right {
      margin-left: px2rem(78);
      .list {
        display: flex;
        align-items: center;
        margin-bottom: px2rem(27);
        font-size: px2rem(24);

        .icon {
          @include bg;
          width: px2rem(34);
          height: px2rem(34);
          margin-right: px2rem(12);
        }
        .wa {
          background-image: url("../../../assets/pc/wa.png");
        }
        .time {
          background-image: url("../../../assets/pc/time.png");
        }
        .email {
          background-image: url("../../../assets/pc/email.png");
          height: px2rem(24);
        }
        .address {
          background-image: url("../../../assets/pc/address.png");
        }
      }
    }

    .copy-right {
      width: px2rem(700);
      font-size: px2rem(24);
      color: #000000;
      line-height: px2rem(29);
      text-align: right;
      margin-top: px2rem(37);
    }
  }
}
