@import "../../common";

.pmp-menu-container {
  .adm-capsule-tabs-header {
    border-bottom: none;
    --adm-color-text-light-solid: #F54617;
    --adm-color-text: #000000;
    padding: 0;
  }
  .adm-capsule-tabs-tab-wrapper {
    padding: 0;
  }
  .adm-capsule-tabs-tab {
    background: none;
    font-weight: 500;
    font-size: px2rem(24);
    padding: 0;
    padding-left: px2rem(50);
  }

}