@import "../../../common";

.eliminacion-container-h5 {
  margin-bottom: mp2r(29);
  .title-wrapper {
    height: mp2r(198);
    background-color: #FFECE7;
    text-align: center;
    padding-top: mp2r(49);
    position: relative;

    .title {
      font-weight: bold;
      font-size: mp2r(18);
      color: #F43900;
      line-height: mp2r(23);
    }
    .cloudy {
      @include bg;
      background-image: url("../../../assets/mobile/cloudy.png");
      height: mp2r(100);
      position: absolute;
      bottom: mp2r(-30);
      left: 0;
      right: 0;
    }
  }
  .content {
    width: mp2r(335);
    margin: mp2r(-10) auto mp2r(50) auto;
    z-index: 1;
    position: relative;
    font-size: mp2r(16);
    color: #999999;

    p {
      margin-bottom: mp2r(15);
    }
    .p-tag {
      margin-bottom: 0;
    }
    .fp {
      font-size: mp2r(16);
      color: #000000;
      line-height: mp2r(21);
      margin-bottom: 0;
    }
    .lp {
      margin-bottom: 0;
      font-weight: 500;
      font-size: mp2r(14);
      color: #000000;
      line-height: mp2r(18);
    }

  }
  .feedback {
    height: mp2r(260);
    background-color: #FFEEE7;
    text-align: center;
    padding-top: mp2r(33);
    .title {
      font-weight: bold;
      font-size: mp2r(26);
      color: #000000;
      line-height: mp2r(32);
    }
    .tips {
      font-size: mp2r(14);
      color: #000000;
      line-height: mp2r(16);
      width: mp2r(287);
      margin: mp2r(39) 0 0 mp2r(20);
      text-align: left;
    }
    .fb-input {
      width: mp2r(337);
      height: mp2r(44);
      border: mp2r(2) dashed #D3D3D3;
      margin: mp2r(7) auto mp2r(13) auto;
      padding-left: mp2r(11);
      --placeholder-color: #757575;
      .adm-input-element {
        font-size: mp2r(14);
      }
    }
    .fb-btn {
      width: mp2r(337);
      height: mp2r(44);
      --background-color: #F43900;
      font-weight: 500;
      font-size: mp2r(18);
      color: #FFFFFF;
      line-height: mp2r(23);
    }
  }

}