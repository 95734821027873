@import "../../../common";

.eliminacion-container-pc {
  .title-wrapper {
    height: px2rem(565);
    background-color: #FFECE7;
    text-align: center;
    padding-top: px2rem(123);
    position: relative;

    .title {
      font-weight: bold;
      font-size: px2rem(50);
      color: #F43900;
      line-height: px2rem(61);
    }
    .cloudy {
      @include bg;
      background-image: url("../../../assets/pc/cloudy.png");
      height: px2rem(329);
      position: absolute;
      bottom: px2rem(-50);
      left: 0;
      right: 0;
    }
  }
  .content {
    width: px2rem(1634);
    margin: px2rem(-50) auto px2rem(120) auto;
    z-index: 1;
    position: relative;
    font-size: px2rem(32);
    color: #999999;
    .abnormal {
      font-weight: 500;
      font-size: px2rem(32);
      color: #000000;
      line-height: px2rem(60);
    }
  }
  .feedback {
    height: px2rem(739);
    background-color: #FFEEE7;
    text-align: center;
    padding-top: px2rem(76);
    .title {
      font-weight: bold;
      font-size: px2rem(40);
      color: #000000;
      line-height: px2rem(49);
    }
    .tips {
      font-size: px2rem(32);
      color: #000000;
      line-height: px2rem(38);
      width: px2rem(1008);
      margin: px2rem(120) auto 0 auto;
      text-align: left;
    }
    .fb-input {
      width: px2rem(1008);
      height: px2rem(114);
      border: px2rem(4) dashed #D3D3D3;
      margin: px2rem(17) auto px2rem(29) auto;
      padding-left: px2rem(33);
      --placeholder-color: #757575;
      .adm-input-element {
        font-size: px2rem(32);
      }
    }
    .fb-btn {
      width: px2rem(1006);
      height: px2rem(112);
      --background-color: #F43900;
      font-weight: 500;
      font-size: px2rem(36);
      color: #FFFFFF;
      line-height: px2rem(44);
    }
  }
}