@import "../../../common";

.pmp-home-container-pc {
  .first {
    height: px2rem(800);
    width: 100%;
    background: linear-gradient(to right, #FDEBE7, #FFF7F4);

    .content {
      margin: 0 auto;
      width: px2rem(1278);
      height: auto;
      padding-top: px2rem(107);
      display: flex;

      .title {
        width: px2rem(710);
        height: px2rem(83);
        font-weight: bold;
        font-size: px2rem(60);
        color: #333333;
        margin-top: px2rem(113);
      }
      .sub-title {
        width: px2rem(573);
        height: px2rem(165);
        font-weight: 500;
        font-size: px2rem(46);
        color: #333333;
        margin: px2rem(41) 0 px2rem(99) 0;
      }
      .google-play {
        cursor: pointer;
        width: px2rem(300);
        height: px2rem(90);
        @include bg;
        background-image: url("../../../assets/pc/google_play.png");
      }
      .download-btn {
        width: px2rem(353);
        height: px2rem(94);
        background-color: #F43900;
        color: #fff;
        line-height: px2rem(94);
        text-align: center;
        border-radius: px2rem(60);
        font-size: px2rem(46);
        font-weight: bolder;
        cursor: pointer;
      }
      .banner-img {
        width: px2rem(541);
        height: px2rem(591);
        @include bg;
        background-image: url("../../../assets/pc/banner.png");
        margin-left: px2rem(27);
      }
    }
  }
  .second {
    .content {
      width: px2rem(1500);
      margin: 0 auto;
      text-align: center;
      padding-top: px2rem(132);
      .title {
        font-weight: bold;
        font-size: px2rem(70);
        color: #000000;
      }
      .sub-title {
        margin: px2rem(27) 0 px2rem(73) 0;
        font-size: px2rem(32);
        line-height: px2rem(38);
        color: #000000;
      }
      .card-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: px2rem(35);
        .card {
          width: px2rem(730);
          height: px2rem(491);
          @include bg;
          background-image: url("../../../assets/pc/bg.png");
          padding: px2rem(42) px2rem(48) 0 px2rem(24);
          display: flex;
          margin-bottom: px2rem(40);
          .icon {
            @include bg;
            width: px2rem(183);
            height: px2rem(183);
            flex: none;
            margin-right: px2rem(11);
          }
          .clock {
            background-image: url("../../../assets/pc/clock.png");
          }
          .lock {
            background-image: url("../../../assets/pc/lock.png");
          }
          .group {
            background-image: url("../../../assets/pc/group.png");
          }
          .hand {
            background-image: url("../../../assets/pc/hand.png");
          }

          .card-title {
            width: px2rem(464);
            font-weight: bold;
            font-size: px2rem(30);
            color: #000000;
            line-height: px2rem(37);
            margin-top: px2rem(13);
            margin-bottom: px2rem(26);
            text-align: left;
          }

          .card-content {
            text-align: left;
            width: px2rem(464);
            font-size: px2rem(26);
            color: #000000;
            line-height: px2rem(32);
          }

        }
      }


    }
  }
  .third {
    background: #FDEBE7;
    height: px2rem(907);

    .content {
      width: px2rem(1368);
      margin: 0 auto;
      padding-top: px2rem(82);
      text-align: center;

      .title {
        font-weight: bold;
        font-size: px2rem(40);
        color: #000000;
        line-height: px2rem(49);
        margin-bottom: px2rem(79);
      }
      .icon-wrapper {
        display: flex;
        .icon {
          @include bg;
        }
        .icons {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: px2rem(212);
            height: px2rem(212);
            margin-bottom: px2rem(19);
          }
          .cloud {
            margin-top: px2rem(170);
            background-image: url("../../../assets/pc/cloud.png");
            &+.icon-title {
              max-width: px2rem(266);
            }
          }
          .verify {
            background-image: url("../../../assets/pc/verify.png");
          }
          .note {
            margin-top: px2rem(170);
            background-image: url("../../../assets/pc/note.png");
          }
          .cash {
            background-image: url("../../../assets/pc/cash.png");
          }
          .icon-title {
            max-width: px2rem(222);
            font-size: px2rem(26);
            color: #000000;
            line-height: px2rem(32);
          }
        }
        .arrow1 {
          width: px2rem(103);
          height: px2rem(74);
          background-image: url("../../../assets/pc/arrow1.png");
          margin-top: px2rem(149);
        }
        .arrow2 {
          width: px2rem(103);
          height: px2rem(74);
          background-image: url("../../../assets/pc/arrow2.png");
          margin-top: px2rem(148);
        }
        .arrow3 {
          width: px2rem(97);
          height: px2rem(74);
          background-image: url("../../../assets/pc/arrow3.png");
          margin-top: px2rem(150);
        }


      }
    }

    .cloudy {
      @include bg;
      width: 100%;
      height: px2rem(432);
      margin-top: px2rem(62);
      background-image: url("../../../assets/pc/cloudy.png");
    }
  }
}
